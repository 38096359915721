<!-- 新建幼儿园 -->
<template>
  <div>
    <ax-form ref="formBox" :formBuilder="formBuilder">
      <div slot="map" style="display: flex">
        <ax-button type="#faad14" @click="dialogVisible = true"
          >地图标绘</ax-button
        >
        <el-dialog
          title="地图标绘"
          width="1000px"
          :visible.sync="dialogVisible"
          :z-index="12"
          :destroy-on-close="true"
          :close-on-click-modal="false"
        >
          <position
            ref="position"
            @close="dialogVisible = false"
            @locationUpdated="handleLocationUpdate"
          />
        </el-dialog>
      </div>
      <div slot="gridId" style="display: flex">
        <a-cascader
          :options="options"
          change-on-select
          placeholder="请选择所属网格"
          @change="onChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          allowClear
          v-decorator="[
            'options',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>

      <!-- 地理位置 -->
      <div slot="address">
        <a-cascader
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :options="options1"
          :load-data="loadData"
          placeholder="请选择位置"
          @change="onChange1"
          allowClear
          :model="residenceCode"
          v-decorator="[
            'options1',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>
    </ax-form>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </div>
</template>
<script>
import api from "./api";
import position from "./position.vue";
const type = [
  { label: "公立", value: "公立" },
  { label: "私立", value: "私立" },
  { label: "其他", value: "其他" },
];
const traffic = [
  { label: "公交车", value: "公交车" },
  { label: "出租车", value: "出租车" },
  { label: "高铁", value: "高铁" },
  { label: "地铁", value: "地铁" },
  { label: "其他", value: "其他" },
];

const formList = [
  {
    label: "幼儿园名称",
    type: "",
    model: "name",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/,
        message: "幼儿园名称不能为空",
      },
    ],
  },
  {
    label: "所属网格",
    type: "",
    model: "gridId",
    options: { placeholder: "请选择网格", showTime: true },
    col: { span: 24 },
  },
  {
    label: "办学类型",
    type: "select",
    model: "type",
    options: { options: type, placeholder: "请选择", showTime: true },
    col: { span: 24 },
    rules: [{ required: true, message: "办学类型不能为空" }],
  },
  {
    label: "院长姓名",
    type: "",
    model: "managerName",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/,
        message: "院长姓名格式不正确",
      },
    ],
  },
  {
    label: "联系方式",
    type: "",
    model: "contact",
    options: { placeholder: "请输入", showTime: true },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern: /^1[3-9]\d{9}$/,
        message: "联系方式格式不正确",
      },
    ],
  },
  {
    label: "位置信息",
    type: "",
    model: "address",
    options: { placeholder: "请输入", showTime: true },
    col: { span: 24 },
  },
  {
    label: "儿童数量",
    type: "",
    model: "number",
    options: { placeholder: "请输入", showTime: true, maxLength: 9 },
    col: { span: 24 },
    rules: [
      {
        required: false,
        pattern: /^[1-9]\d{0,8}$/,
        message: "格式不正确",
      },
    ],
  },
  {
    label: "周边交通类型",
    type: "select",
    model: "transport",
    options: {
      options: traffic,
      mode: "multiple",
      optionFilterProp: "label",
      labelInValue: false,
      placeholder: "请选择",
    },
    col: { span: 24 },
  },
  {
    label: "安全隐患",
    type: "",
    model: "safetyDesc",
    options: { placeholder: "请输入", showTime: true, maxLength: 200 },
    col: { span: 24 },
  },
  // {
  //   label: "场所纬度",
  //   type: "",
  //   model: "latitude",
  //   options: { placeholder: "请输入内容", showTime: true },
  //   col: { span: 12 },
  //   // rules: [{ required: true, message: "位置信息不能为空" }],
  // },
  // {
  //   label: "场所经度",
  //   type: "",
  //   model: "longitude",
  //   options: { placeholder: "请输入内容", showTime: true },
  //   col: { span: 12 },
  //   // rules: [{ required: true, message: "位置信息不能为空" }],
  // },
  {
    label: "地图位置",
    type: "",
    model: "map",
    col: { span: 24 },
    options: { placeholder: "请输入内容", showTime: true },
  },
];
export default {
  components: {
    position,
  },
  data() {
    return {
      api,
      gridId: "",
      gridAddress: "",
      latitude: "",
      longitude: "",
      options: [],
      saveType: false,
      options1: [],
      domicileValue: {},
      address: "",
      residenceCode: "",
      dialogVisible: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 105 },
        { col: { span: 8 } }
      ),
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.owningGrid();
    this.getRegionInfo();
  },
  methods: {
    handleLocationUpdate(locationData) {
      // 接收并处理传递过来的经纬度值
      // console.log("收到的经度值:", locationData.lng);
      this.latitude = locationData.lng;
      // console.log("收到的纬度值:", locationData.lat);
      this.longitude = locationData.lat;
    },
    // 获取省级行政区划
    async getRegionInfo() {
      const res = await api.getRegionInfo();
      // console.log(res, "省级");
      this.options1 = res.data.map((res1) => {
        return {
          label: res1.areaName,
          value: res1.provinceCode,
          isLeaf: false,
        };
      });
    },
    // 级联选择器改变的回调(地理位置)
    onChange1(value, e) {
      if (e) {
        // console.log(value, e);
        const address = e.map((item) => item.label).join("/");
        this.address = address;
        // console.log(this.address);
        //传最后一个id
        this.residenceCode = value[value.length - 1];
      }
    },
    // 分批获取下一级数据
    async loadData(selectedOptions) {
      // console.log(selectedOptions, "361");
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      // 判断当前选项的级别
      switch (selectedOptions.length) {
        case 1:
          // 加载第二级数据
          const res2 = await api.getProvinceRegionInfo(targetOption.value);
          // console.log(res2, "370");
          targetOption.children = res2.data.map((res1) => ({
            label: res1.areaName,
            value: res1.cityCode,
            isLeaf: false,
          }));
          break;
        case 2:
          // 加载第三级数据
          const res3 = await api.getCityRegionInfo(targetOption.value);
          // console.log(res3, "380");
          targetOption.children = res3.data.map((res1) => ({
            label: res1.areaName,
            value: res1.countyCode,
            isLeaf: false,
          }));
          break;
        case 3:
          // 加载第四级数据
          const res4 = await api.getCountyRegionInfo(targetOption.value);
          // console.log(res4, "390");
          targetOption.children = res4.data.map((res1) => ({
            label: res1.areaName,
            value: res1.townCode,
            isLeaf: true, // 最后一级为叶子节点
          }));
          break;
        default:
          break;
      }
      targetOption.loading = false;
      this.options = [...this.options];
    },
    // /////////////////
    // // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
          // console.log(options, "3333333333");
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // console.log(value);
      // 获取最后一个ID
      if (e) {
        const lastId = value[value.length - 1];
        const address = e.map((item) => item.label).join("/");
        // console.log("最后一个ID:", lastId);
        this.gridAddress = address;
        this.gridId = lastId;
      }

      // console.log(this.gridId);
    },
    ///////////////////
    //提交
    async onSubmit() {
      // this.saveType = true;
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;

        value.kind = 0;
        value.gridId = this.gridId; //所属网格
        value.gridAddress = this.gridAddress; //所属网格地址
        value.address = this.address; //位置信息
        value.latitude = this.latitude;
        value.longitude = this.longitude;
        if (!this.gridId) {
          this.$message.error("所属网格不能为空");
          return;
        }
        if (!this.address) {
          this.$message.error("位置信息不能为空");
          return;
        }
        // if (!this.latitude || !this.longitude) {
        //   this.$message.error("请点击地图标绘");
        //   return;
        // }
        this.saveType = true; // 验证通过后再设置
        // console.log(value);
        try {
          const res = await api.add(value);
          // console.log(res);
          if (res.status === 200) {
            this.$message.success("新增成功");
            this.saveType = false;
            // 提交成功后关闭弹窗
            this.$emit("closeDialog");
            this.refreshTable();
          } else {
            this.$message.error("新增失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (error) {
          console.error(error);
          this.$message.error("新增失败");
          this.saveType = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("closeDialog");
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
      // console.log("refreshTable");
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
</style>
    